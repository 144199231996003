import React, { useState, useEffect } from 'react';

import "../../main.css";
import "./HeaderStyle.css";

const Footer = () => {
    return (
        <>
            <div class="header"></div>
        </>
    );
};

export default Footer;